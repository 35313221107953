import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Loading from "../../Components/Shared/Loading";

const Profile = () => {
    return (<div>This is private</div>)
}

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Loading />,
});