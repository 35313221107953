import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button
    className="block w-full text-gray-300 px-3 py-2 rounded-md text-base font-medium hover:text-white hover:bg-gray-700 md:text-sm"
    onClick={() => loginWithRedirect()}>
    Log In
      </button>;
};

export default LoginButton; 