import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../Store/rootReducer";
import Loading from "../../../Components/Shared/Loading";
import { fetchStoryDetails } from "../storyDetailsSlice";
import { StoryDetailsResult } from "../../../Api/apiClient";
import { useParams  } from "react-router-dom";
import DateDisplay from "../../../Components/Dates/DateDisplay";
import { H2 } from "../../../Components/Headings/Headings";


export const StoryPage = () => {
    const dispatch = useDispatch();
    const {storyId} = useParams();
    const { stories, error, isLoading } = useSelector((state: RootState) => state.stories);
    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        if([...stories].some(s => s.id === storyId)) {
            return;
        }
        console.log("fetching");
        const fetchStory = async () => {
            const accessToken = await getAccessTokenSilently();
            dispatch(fetchStoryDetails(accessToken, storyId));
        }
        fetchStory();
    }, [dispatch, getAccessTokenSilently, stories, storyId])

    if (isLoading) {
        return (<Loading />)
    }

    if (error) {
        return (<div>Some error happened. {error}</div>)
    }

    const story = [...stories].find(s => s.id === storyId);

    if(!story) {
        return(<div>Not found</div>);
    }

    return (
        <StoryDetails story={story} />
    )
}

interface SummaryProps {
    story: StoryDetailsResult
}

function StoryDetails(props: SummaryProps) {
    const { story } = props;

    return (
        <div className="mt-6">
            <div className="max-w-4xl mx-auto px-10 py-6">
                <div className="flex justify-between items-center">
                    <H2 text={story.title} />
                    <DateDisplay date={story.eventDateUtc} />
                </div>
                <div className="mt-2">
                    <p className="mt-2 text-gray-600">{story.content}</p>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div><h1 className="text-gray-700 font-bold">Some Author</h1></div>
                </div>
            </div>
        </div>
    )
}
