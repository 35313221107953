import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom";
import { StorySummaryResult } from "../../../Api/apiClient";
import DateDisplay from "../../../Components/Dates/DateDisplay";
import Loading from "../../../Components/Shared/Loading";
import { RootState } from "../../../Store/rootReducer";
import { fetchStorySummaries, setHighlightedYear } from "../storySummarySlice";


export const StoriesPage = () => {
    const dispatch = useDispatch();
    const { storySummaries, error, isLoading, initialised, highlightedYear } = useSelector((state: RootState) => state.storySummaries);
    const { getAccessTokenSilently } = useAuth0();
    const years = [...new Set(storySummaries.stories.map(s => new Date(s.eventDateUtc).getFullYear()))];
    useEffect(() => {
        if (!initialised) {
            const fetchStory = async () => {
                const accessToken = await getAccessTokenSilently();
                dispatch(fetchStorySummaries(accessToken));
            }
            fetchStory();
        }
    }, [dispatch, getAccessTokenSilently, initialised])

    if (isLoading) {
        return (<Loading />)
    }

    if (error) {
        return (<div>Some error happened. {error}</div>)
    }

    return (
        <div className="md:grid md:grid-cols-9" id="stories-list">
            <div className="md:col-span-8">{storySummaries?.stories.map(s => <StorySummary summary={s} key={s.id} />)}</div>
            <div className="hidden md:col-span-1 md:pl-7 md:flex md:max-h-52 md:overflow-y-auto"><div className="md:flex-auto md:flex-col md:fixed">{years.map((year, i) => {
                const classes = clsx(year === highlightedYear ? "font-medium text-gray-800 dark:text-yellow-800" : "font-light text-gray-600  dark:text-yellow-600")
                return (<div key={i} className={classes}>{year}</div>)
            })}</div></div>
            <AddStoryButton />
        </div>
    )
}

interface SummaryProps {
    summary: StorySummaryResult
}

function StorySummary(props: SummaryProps) {
    const { summary } = props;
    const highlightedYear = useSelector((state: RootState) => state.storySummaries.highlightedYear);
    const dispatch = useDispatch();
    const [ref, inView] = useInView({
        rootMargin: "-50%"
    });

    useEffect(() => {
        const currentYear = new Date(summary.eventDateUtc).getFullYear();
        if (inView && (highlightedYear !== currentYear)) {
            dispatch(setHighlightedYear(currentYear));
        }
    }, [inView, summary.eventDateUtc, dispatch, highlightedYear]);
    return (
        <div className="mt-6" ref={ref}>
            <div className="max-w-8xl px-10 py-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center">
                    <span className="text-2xl text-gray-700 font-bold">{summary.title}</span>
                    <DateDisplay date={summary.eventDateUtc} />
                </div>
                <div className="mt-2">
                    <p className="mt-2 text-gray-600">{summary.content}</p>
                </div>
                <div className="flex justify-between items-center mt-4">
                    <Link to={`/stories/${summary.id}`} className="text-blue-500 hover:underline">Read more</Link>
                    <div><h1 className="text-gray-700 font-bold">Some Author</h1></div>
                </div>
            </div>
        </div>
    )
}

function AddStoryButton() {
    const navigate = useNavigate();
    function handleOnClick() {        
        navigate('/stories/new')
    }

    return (<div className="fixed right-5 md:right-11 bottom-5 md:bottom-11">
        <button
            onClick={handleOnClick}
            className="p-0 w-10 md:w-20 h-10 md:h-20 bg-indigo-500 rounded-full hover:bg-indigo-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
            <svg viewBox="0 0 20 20" enableBackground="new 0 0 20 20" className="w-6 md:w-9 h-9 md:h-9 inline-block">
                <path fill="#FFFFFF" d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                            C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                            C15.952,9,16,9.447,16,10z" />
            </svg>
        </button>
    </div>);
}