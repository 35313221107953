import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getStory, addStory, StoryDetailsRequest, StoryDetailsResult, StorySummaryResult } from "../../Api/apiClient";
import { AppThunk } from "../../Store/store";
import { addStorySummary } from "./storySummarySlice";

type StoryDetailsState = {
    stories: StoryDetailsResult[],
    error: string | null,
    isLoading: boolean,
    isSaving: boolean
}

let initialState: StoryDetailsState = {
    stories: [],
    error: null,
    isLoading: true,
    isSaving: false
}

const storyDetails = createSlice({
    name: 'storyDetails',
    initialState: initialState,
    reducers: {
        getStoryDetailsStart(state) {
            state.isLoading = true;
        },
        getStoryDetailsSuccess(state, action: PayloadAction<StoryDetailsResult>) {
            //only add unique items to the state
            if (!state.stories.some(s => s.id === action.payload.id)) {
                state.stories.push(action.payload);
            }
            state.isLoading = false;
            state.error = null;
        },
        getStoryDetailsFailed(state, action: PayloadAction<string>) {
            state.stories = state.stories ?? [];
            state.isLoading = false;
            state.error = action.payload;
        },
        addStoryStart(state) {
            state.isSaving = true;
        },
        addStorySuccess(state, action: PayloadAction<StoryDetailsResult>) {
            state.isSaving = false;
            if (!state.stories.some(s => s.id === action.payload.id)) {
                console.log(action.payload);
                //todo order stories by event date
                state.stories.push(action.payload);
            }
            state.error = null;
        },
        addStoryFailed(state, action: PayloadAction<string>) {
            state.isSaving = false;
            state.error = action.payload;
        }
    }
})

export const {
    getStoryDetailsStart,
    getStoryDetailsSuccess,
    getStoryDetailsFailed,
    addStoryStart: addStoryStart,
    addStorySuccess: addStorySuccess,
    addStoryFailed: addStoryFailed
} = storyDetails.actions;

export default storyDetails.reducer;

export const fetchStoryDetails = (
    accessToken: string,
    storyId: string
): AppThunk => async dispatch => {
    try {
        dispatch(getStoryDetailsStart())
        const storyDetails = await getStory(accessToken, storyId);
        dispatch(getStoryDetailsSuccess(storyDetails));
    } catch (error) {
        dispatch(getStoryDetailsFailed(error.toString()))
    }
}

export const addNewStory = (
    accessToken: string,
    newStory: StoryDetailsRequest
): AppThunk => async dispatch => {
    try {
        dispatch(addStoryStart())
        const storyDetails = await addStory(accessToken, newStory);
        dispatch(addStorySuccess(storyDetails));
        const summary: StorySummaryResult = {
            content: storyDetails.content,
            eventDateUtc: storyDetails.eventDateUtc,
            id: storyDetails.id,
            title: storyDetails.title
        }
        dispatch(addStorySummary(summary))
    } catch (error) {
        dispatch(addStoryFailed(error.toString()))
    }
}
