const apiBaseUrl: string = process.env.REACT_APP_API_HOST_BASE ?? '';

export interface StoryDetailsResult {
  title: string,
  content: string,
  eventDateUtc: Date,
  createdDateUtc: Date,
  id: string,
  creatorName: string
}

export interface StoryDetailsRequest {
  title: string,
  content: string,
  eventDateUtc: Date,
  creatorName: string
}

export async function getStory(accessToken: string, storyId: string): Promise<StoryDetailsResult> {
  try {
    const url = `${apiBaseUrl}/stories/${storyId}`;

    const storyResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if(storyResponse.ok) {
      const story: StoryDetailsResult = await storyResponse.json();

      return story;
    }

    throw('Reponse was not good.');
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export interface StorySummariesResult {
  stories: StorySummaryResult[],
  lastEvaluatedKey: string | null
}

export interface StorySummaryResult {
  id: string;
  title: string,
  content: string,
  eventDateUtc: Date
}

export async function getStories(accessToken: string): Promise<StorySummariesResult> {
  try {
    const url = `${apiBaseUrl}/stories`;

    const storyResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if(storyResponse.ok) {
      const story: StorySummariesResult = await storyResponse.json();

      return story;
    }

    throw('Reponse was not good.');
    
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}

export async function addStory(accessToken: string, newStory: StoryDetailsRequest): Promise<StoryDetailsResult> {
  try {
    const url = `${apiBaseUrl}/stories`;
    const headers: HeadersInit = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };
    const request: RequestInit = {
      method: "POST",
      headers,
      body: JSON.stringify(newStory)
    }
    const storyResponse = await fetch(url, request);

    if(storyResponse.ok) {
      const story: StoryDetailsResult = await storyResponse.json();

      return story;
    }

    throw('Reponse was not good.');
  } catch (e) {
    console.log(e.message);
    throw e;
  }
}