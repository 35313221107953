import { combineReducers } from '@reduxjs/toolkit';
import storiesDisplayReducer from '../Features/Stories/storyDetailsSlice';
import storySummariesReducer from '../Features/Stories/storySummarySlice';
import appStateReducer from '../Features/App/appSlice';


const rootReducer = combineReducers({
    appState: appStateReducer,
    stories: storiesDisplayReducer,
    storySummaries: storySummariesReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;