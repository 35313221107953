import React from 'react';
import './App.css';
import About from './Features/App/About';
import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Navigation from './Components/Navigation';
import Profile from './Features/User/Profile';
import { StoriesPage } from './Features/Stories/Pages/StoriesPage';
import { StoryPage } from './Features/Stories/Pages/StoryPage';
import Loading from './Components/Shared/Loading';
import { AddStoryPage } from './Features/Stories/Pages/AddStoryPage';
import { HomePage } from './Features/Home/HomePage';

function App() {

  return (
    <Auth0Provider
      domain="memorystoria.eu.auth0.com"
      clientId="4nvWwLCpNv7QYdjG9BNY7HuCW4MiduU6"
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <Router>
        <Navigation />
        <div className="bg-gray-100">
          <Routes>
            <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <ProtectedRoute path="/profile" element={<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"><Profile /></div>} />
              <ProtectedRoute path="/stories" element={<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"><StoriesPage /></div>} />
              <ProtectedRoute path="/stories/:storyId" element={<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"><StoryPage /></div>} />
              <ProtectedRoute path="/stories/new" element={<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"><AddStoryPage /></div>} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </Auth0Provider>
  );
}

export default App;

const ProtectedRoute = ({ children, ...args }: any) => {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return (<div>You must log in</div>)
  }
  return (
    <Route
      element={withAuthenticationRequired(children, {
        onRedirecting: () => <Loading />
      })}
      {...args}
    />
  )
};

const Footer = () => {
  return(
<footer className="footer bg-gray-100 relative pt-1 border-b-2 border-blue-700">
    <div className="container mx-auto px-6">

        <div className="sm:flex sm:mt-8">
            <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex flex-col md:flex-row justify-between">
                <div className="flex flex-col">
                    <span className="font-bold text-gray-700 uppercase mb-2">Contact</span>
                    <span className="my-2">info@memorystoria.com</span>
                </div>
            </div>
        </div>
    </div>
    <div className="container mx-auto px-6">
        <div className="mt-16 border-t-2 border-gray-300 flex flex-col items-center">
            <div className="sm:w-2/3 text-center py-6">
                <p className="text-sm text-blue-700 font-bold mb-2">
                    © 2020 MemoryStoria
                </p>
            </div>
        </div>
    </div>
</footer>
  )
}



