import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import LoginButton from "./Buttons/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import LogoutButton from "./Buttons/LogoutButton";

export default function Navigation() {
    const [isMobileOpen, setMobileOpen] = useState(false);
    const { isAuthenticated, user, isLoading } = useAuth0();

    return (
        <nav className="bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <DesktopNavItems isLoading={isLoading} isAuthenticated={isAuthenticated} />
                    {(!isLoading && isAuthenticated) && <DesktopProfileButton user={user} />}
                    <MobileMenuIcon setMobileOpen={setMobileOpen} isMobileOpen={isMobileOpen} />
                </div>
            </div>
            {isMobileOpen && <MobileNavMenu user={user} isAuthenticated={isAuthenticated} />}

        </nav>
    )
}

interface MobileNavMenuProps {
    user: any,
    isAuthenticated: boolean
}

const MobileNavMenu = (props: MobileNavMenuProps) => {
    const { user, isAuthenticated } = props;
    return (
        <div className={`'block md:hidden`}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <NavLink to="/" activeClassName="bg-gray-900 text-white" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Home</NavLink>
                <NavLink to="/about" activeClassName="bg-gray-900 text-white" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">About</NavLink>
            </div>
            {isAuthenticated ? <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                        <ProfileImage imageUrl={user.picture} />
                    </div>
                    <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">{user.name}</div>
                        <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                    {isAuthenticated && <NavLink to="/stories" activeClassName="bg-gray-900 text-white" className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Stories</NavLink>}
                    <NavLink to="/profile" activeClassName="bg-gray-900 text-white" className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Your Profile</NavLink>
                    <LogoutButton />
                </div>
            </div> :
            <div className="pt-3 pb-3 border-t border-gray-700">
            <div className="px-2 space-y-1">
                <LoginButton /></div></div>}
        </div>
    )
}

interface DesktopNavProps {
    isLoading: boolean,
    isAuthenticated: boolean
};

const DesktopNavItems = (props: DesktopNavProps) => {
    const { isLoading, isAuthenticated } = props;
    return (
        <div className="flex items-center">
            <div className="hidden md:block">
                <div className="flex items-baseline space-x-4">
                    <NavLink to="/" activeClassName="bg-gray-900 text-white" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</NavLink>
                    <NavLink to="/about" activeClassName="bg-gray-900 text-white" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">About</NavLink>
                    {isAuthenticated && <NavLink to="/stories" activeClassName="bg-gray-900 text-white" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Stories</NavLink>}
                    {(!isLoading && !isAuthenticated) && <LoginButton />}
                </div>
            </div>
        </div>
    )
}

const MobileMenuIcon = (props: any) => {
    const { setMobileOpen, isMobileOpen } = props;
    const svgClasses = clsx(isMobileOpen && 'block', 'h-6 w-6')
    return (<div className="-mr-2 flex md:hidden">
        <button onClick={() => setMobileOpen(!isMobileOpen)} className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span className="sr-only">Open main menu</span>
            <svg className={svgClasses} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isMobileOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
        </button>
    </div>)
};

const DesktopProfileButton = (props: any) => {
    const picture: string = props.user.picture;
    return (<div className="hidden md:block">
        <div className="ml-4 flex items-center md:ml-6">
            <div className="ml-3 relative">
                <div>
                    <button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
                        <span className="sr-only">Open user menu</span>
                        <ProfileImage imageUrl={picture} />
                    </button>
                </div>
            </div>
        </div>
    </div>)
};

interface ProfileImageProps {
    imageUrl: string
}

const ProfileImage = (props: ProfileImageProps) => {
    return (
        <img className="h-8 w-8 rounded-full" src={props.imageUrl} alt="" />
    )
}
