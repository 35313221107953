import clsx from "clsx"

interface HeadingProps {
    text: string,
    classes?: string
}

export const H1 = (props: HeadingProps) => {
    const classes = clsx('text-4xl font-bold', props.classes)
    return(<h1 className={classes}>{props.text}</h1>)
}

export const H2 = (props: HeadingProps) => {
    return(<h2 className="text-3xl font-bold">{props.text}</h2>)
}

export const H3 = (props: HeadingProps) => {
    return(<h3 className="text-2xl text-gray-600 font-bold">{props.text}</h3>)
}