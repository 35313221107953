import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function LogoutButton() {
  const {
    isAuthenticated,
    logout,
  } = useAuth0();

  return isAuthenticated ? (
    <button className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium" onClick={() => {
      logout({ returnTo: window.location.origin });
    }}>Log out</button>
  ) : null;
}

export default LogoutButton;