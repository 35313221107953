import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getStories, StorySummariesResult, StorySummaryResult } from "../../Api/apiClient";
import { AppThunk } from "../../Store/store";

type StorySummariesState = {
    storySummaries: StorySummariesResult,
    error: string | null,
    isLoading: boolean,
    initialised: boolean,
    highlightedYear: number | null;
}

let initialState: StorySummariesState = {
    storySummaries: {
        stories: [],
        lastEvaluatedKey: null
    },
    error: null,
    isLoading: true,
    initialised: false,
    highlightedYear: null
}

const storySummaries = createSlice({
    name: 'storySummaries',
    initialState: initialState,
    reducers: {
        getStorySummariesStart(state) {
            state.isLoading = true;
        },
        getStorySummariesSuccess(state, action: PayloadAction<StorySummariesResult>) {
            state.storySummaries = action.payload;
            state.isLoading = false;
            state.error = null;
            state.storySummaries.lastEvaluatedKey = null;
            state.initialised = true
        },
        getStorySummariesFailed(state, action: PayloadAction<string>) {
            state.storySummaries.stories = [];
            state.isLoading = false;
            state.error = action.payload;
        },
        addStorySummary(state, action: PayloadAction<StorySummaryResult>) {
            state.storySummaries.stories.push({
                id: action.payload.id,
                content: action.payload.content,
                eventDateUtc: action.payload.eventDateUtc,
                title: action.payload.title
            })
            state.error = null;
        },
        setHighlightedYear(state, action: PayloadAction<number>) {
            state.highlightedYear = action.payload;
        }
    }
})

export const {
    getStorySummariesStart,
    getStorySummariesSuccess,
    getStorySummariesFailed,
    addStorySummary,
    setHighlightedYear
} = storySummaries.actions;

export default storySummaries.reducer;

export const fetchStorySummaries = (
    accessToken: string
): AppThunk => async dispatch => {
    try {
        dispatch(getStorySummariesStart())
        const storyDetails = await getStories(accessToken);
        dispatch(getStorySummariesSuccess(storyDetails));
    } catch (error) {
        dispatch(getStorySummariesFailed(error.toString()))
    }
}
