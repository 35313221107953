import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import { useForm, FormProvider, useFormContext, FieldError, RegisterOptions } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store/rootReducer";
import { addNewStory } from "./storyDetailsSlice";

export type StoryFormInputs = {
    title: string,
    content: string,
    creatorName: string,
    eventDateUtc: Date
};

export const StoryForm = () => {
    const methods = useForm<StoryFormInputs>();

    const { error, isSaving } = useSelector((state: RootState) => state.stories);

    const navigate = useNavigate();

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();

    const onSubmit = (data: StoryFormInputs) => {
        const addStory = async () => {
            const accessToken = await getAccessTokenSilently({});
            dispatch(addNewStory(accessToken, {
                content: data.content,
                creatorName: data.creatorName,
                eventDateUtc: data.eventDateUtc,
                title: data.title
            }));
        };
        addStory();
        navigate('/stories');
    };
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6 space-y-6">
                        <div className="grid grid-cols-6 gap-6">
                            <TextInput label="Story title" placeholder="My personal story" fieldName="title" options={{ required: true }} />
                            <DateInput label="Event date" fieldName="eventDateUtc" options={{ required: true }} />
                        </div>
                        <div className="grid grid-cols-6 gap-6">
                            <TextInput label="Author's name" placeholder="Your name" fieldName="creatorName" options={{ required: true }} />
                        </div>
                        <div className="grid grid-cols-6">
                            <TextAreaInput label="Your story" placeholder="A long time ago, in a galaxy far far away..." fieldName="content" options={{ required: true }} />
                        </div>
                        <button className="p-4 bg-blue-400 rounded-md mt-3" type="submit" disabled={isSaving} >Save</button>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
interface ErrorProps {
    errorMessage: string;
}
const FormError = (props: ErrorProps) => {
    return (
        <span className="text-red-600">{props.errorMessage}</span>
    );
};
interface InputProps {
    label?: string;
    placeholder?: string;
    fieldName: string;
    options?: RegisterOptions;
}

const TextAreaInput = (props: InputProps) => {
    const { register, errors } = useFormContext();
    const error: FieldError = Object.keys(errors).includes(props.fieldName) ? errors[props.fieldName] : null;
    const inputClasses = clsx("mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md", error ? "border-red-600" : "border-gray-300");
    return (
        <div className="col-span-6">
            <div className="flex justify-between">
                {props.label && <label htmlFor={props.fieldName} className="text-sm font-medium text-gray-700">{props.label}</label>}
                {props.options?.required ? <span className="text-xs text-red-600">required</span> : null}
            </div>
            <textarea
                ref={register(props.options)}
                name={props.fieldName}
                id={props.fieldName}
                placeholder={props.placeholder}
                autoComplete="off"
                className={inputClasses} 
                rows={10}/>
            {error?.message && <FormError errorMessage={"Please check this"} />}
        </div>
    )
}

const DateInput = (props: InputProps) => {
    return(<GenericInput type="date" formData={props} />)
};

const TextInput = (props: InputProps) => {
    return(<GenericInput type="text" formData={props} />)
};

interface GenericInputProps {
    type: 'date' | 'text',
    formData: InputProps
}

const GenericInput = (props: GenericInputProps) => {
    const { register, errors } = useFormContext();
    const {type, formData} = props;
    const error: FieldError = Object.keys(errors).includes(formData.fieldName) ? errors[formData.fieldName] : null;
    const inputClasses = clsx("mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md", error ? "border-red-600" : "border-gray-300");
    return (
        <div className="col-span-6 sm:col-span-3">
            <div className="flex justify-between">
                {formData.label && <label htmlFor={formData.fieldName} className="text-sm font-medium text-gray-700">{formData.label}</label>}
                {formData.options?.required ? <span className="text-xs text-red-600">required</span> : null}
            </div>
            <input
                ref={register(formData.options)}
                type={type}
                name={formData.fieldName}
                id={formData.fieldName}
                placeholder={formData.placeholder}
                autoComplete="off"
                className={inputClasses} />
            {error?.message && <FormError errorMessage={"Please check this"} />}
        </div>
    );
}
