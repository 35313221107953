import { createSlice } from "@reduxjs/toolkit"

type AppState = {
}

let initialState: AppState = {
}

const appState = createSlice({
    name: 'appState',
    initialState: initialState,
    reducers: {
    }
})

export const {
} = appState.actions;

export default appState.reducer;
