import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { H1, H2 } from "../../Components/Headings/Headings";
import { StoryForm } from "../Stories/StoryForm";
import hero from "./hero.jpg";

export const HomePage = () => {
    const { isAuthenticated } = useAuth0();
    return (
        <>
            <HeroBanner />
            <section className="grid grid-cols-1 md:grid-cols-2 mr-12 ml-12 mt-6 pb-6">
                <div className="col-span-2 lg:text-center mb-6">
                    <H2 text="MemoryStoria" />
                    <p className="mt-2 text-xl leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        Share your memories with your friends and family
                    </p>
                </div>
                <div className="col-span-2 md:col-span-1 space-y-4">
                    {/* Icon and text */}
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                </svg>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                Record your stories
                        </dt>
                            <dd className="mt-2 text-base text-gray-500">
                                MemoryStoria allows you to record all your personal stories to build up your own digital memoir you can view anytime
                        </dd>
                        </div>
                    </div>

                    {/* Icon and text */}
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                </svg>
                            </div>
                        </div>
                        <div className="ml-4">
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                You can store all your memoirs for free, forever.
                        </dt>
                            <dd className="mt-2 text-base text-gray-500">
                                Premium features like adding rich media and linking your memories with your friends is coming soon
                        </dd>
                        </div>
                    </div>
                </div>
                {isAuthenticated && <StoryForm />}
            </section>
            {/* <LatestStory /> */}
        </>
    )
}

const HeroBanner = () => {
    const gridStyle = {
        gridArea: '1 / 1 / 2 / 2'
    };
    const rowHeight = {
        gridTemplateRows: '500px'
    };
    return (
        <section className="grid grid-cols-1 content-center justify-center" style={rowHeight}>
            <div className="banner-image-div" style={gridStyle}>
                <img className="banner-image grid w-full h-full object-cover" src={hero} alt="Nostalgic old person" />
            </div>
            <div className="banner-overlay-div grid max-w-full bg-gradient-to-l from-gray-800 to-transparent" style={gridStyle}></div>
            <div className="banner-text-div grid items-center ml-40 mr-40" style={gridStyle}>
                <span className="banner-text">
                    <H1 text="MemoryStoria" classes="text-white" />
                    <p className="text-white">Bring your story to life</p>
                </span>
            </div>
        </section>
    )
}